<template>
    <div>
        <baseInfo :orderBean="orderBean" isEdit/>

        <OrderPortray v-if="showNewPortray"/>
        <orderFeature v-else/>

        <purchaseSum/>
    </div>
</template>

<script>
import baseInfo from '@/components/order/common/BaseInfo'
import purchaseSum from '@/components/order/common/PurchaseSum2'
import orderFeature from '@/components/order/common/OrderFeature'
import OrderPortray from '@/components/order/common/OrderPortray'

export default {
  components: {
    baseInfo, purchaseSum, orderFeature, OrderPortray
  },
  data () {
    return {
      showNewPortray: this.$store.getters.token.publisherSetting.stationPortray === 2 // 是否显示新版站点画像
    }
  },
  computed: {
    orderBean () {
      return this.$store.state.order.orderBean
    }
  }
}
</script>
