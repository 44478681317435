import request from '@/utils/requestV2'
const qs = require('qs')

// 删除订单产品
export function deleteProduct (data) {
  return request({
    url: '/ooh-order/v1/orderproduct/deleteproduct',
    method: 'post',
    data: qs.stringify(data)
  })
}
