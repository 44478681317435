<style scoped>
.choose-product{
  font-weight: bold;
  border-bottom: 2px solid #2d8cf0;
}
</style>

<template>
  <div>
    <template>
      <Divider dashed ><span class="divider-text">订单费用汇总</span></Divider>
      <Row class="p-b-5">
          <i-col span="12" class="title">
              发布费刊例合计
          </i-col>
          <i-col span="12" class="text-right">{{formatMoney(serviceData.totalUsePrice)}}</i-col>
      </Row>
      <Row class="p-b-5" v-for="(item,index) in serviceData.rulePriceResultList" :key="'a' + index">
          <i-col span="12" class="title">
              {{item.productName}}-{{item.ruleTypeName}}(折扣：{{(item.value*100).toFixed(2)}}%)
          </i-col>
          <i-col span="12" class="text-right">{{formatMoney(item.usePrice)}}</i-col>
      </Row>
      <Row class="text-14">
          <i-col span="8"><strong>合计:</strong></i-col>
          <i-col span="16" class="text-right text-orange">{{formatMoney(serviceData.finalUsePrice)}}</i-col>
      </Row>
    </template>

    <Divider dashed ><span class="divider-text">采购资源汇总</span></Divider>
    <div v-for="category in categories" :key="category.categoryId" class="text-14">
        <div v-for="(schedule,i) in category.schedules" :key="i">
            <row>
                <Tag  type="border" color="black">{{category.category}} {{formatSchedule(schedule)}}  </Tag>
            </row>
            <div v-for="product in schedule" :key="product.id">
                <row>
                    <i-col span="16">
                        <a :class="chooseProduct === product?'choose-product':''" @click="handleProductChosen(product, true)">{{product.productName}}({{product.quantity+product.productUnit}})</a>
                        <Icon type="ios-trash-outline" :size="20" style="color:#ed3f14;cursor:pointer;" @click="handleDeleteWarning(product)"></Icon>
                    </i-col>
                    <i-col span="8" class="text-right">
                        {{formatMoney(product.usePrice)}}
                    </i-col>
                </row>
            </div>
        </div>
    </div>
    <p v-if="categories.length===0" class="text-center remark">暂无数据</p>

    <Spin fix size="large" v-if="loading">
        <Icon type="ios-loading" size=18 class="spin-icon-load"></Icon>
        <div>数据加载中...</div>
    </Spin>
  </div>
</template>

<script>
import { sysMixins } from '@/assets/mixins/sys'
import { getProductPrice, getSummaryPrice } from '@/api/order/orderprice'
import { deleteProduct } from '@/api/order/orderproduct'

import { GetCurrentSchedule } from '@/utils/dateFormat'
import { toMoney } from '@/utils/wnumb_own'
import { buildCategoriesOrder } from '@/utils/orderUtils'

export default {
  mixins: [sysMixins],
  data () {
    return {
      products: [],
      chooseProduct: {},
      categories: [],
      special: {},
      loading: false,
      serviceData: []
    }
  },
  mounted () {
    this.initPageData()
  },
  methods: {
    initPageData () {
      this.chooseProduct = null
      this.loading = true
      getProductPrice({ orderId: this.orderId }).then(res => {
        this.products = res || []
        // 获取常规采购的产品分类
        this.categories = buildCategoriesOrder(this.products.filter(x => x.priceType === 1))

        // 默认选中数据
        if (this.categories.length > 0 && this.categories[0].schedules.length > 0 && this.defaultProduct) {
          let flag = false

          for (let i1 = 0; i1 < this.categories.length; i1++) {
            if (flag) { break }

            for (let i2 = 0; i2 < this.categories[i1].schedules.length; i2++) {
              if (flag) { break }

              for (let i3 = 0; i3 < this.categories[i1].schedules[i2].length; i3++) {
                if (this.categories[i1].schedules[i2][i3].productId === this.defaultProduct.productId &&
                  this.categories[i1].schedules[i2][i3].startDate === this.defaultProduct.startDate &&
                  this.categories[i1].schedules[i2][i3].endDate === this.defaultProduct.endDate) {
                  flag = true
                  this.handleProductChosen(this.categories[i1].schedules[i2][i3])
                  break
                }
              }
            }
          }

          // 数据已被删除，需要清空地图
          if (!flag) { this.clearMapData() }
        } else {
          this.clearMapData()
        }

        this.loading = false
      })

      // 获取订单费用汇总
      getSummaryPrice({ orderId: this.orderId }).then(res => {
        this.serviceData = res
      })
    },
    clearMapData () {
      this.$store.commit('set_order_bottom_product', null)

      if (this.mapType === 'purchase') {
        this.$store.commit('set_order_update_map', new Date())
        this.$store.commit('set_order_stations', [])
      }
    },
    handleProductChosen (product, showPoint) {
      if (showPoint) {
        this.$store.commit('set_order_map_type', 'purchase')
      }

      this.chooseProduct = product
      this.$store.commit('set_order_defaultProduct', product)
      const newProduct = Object.assign({}, product, { orderId: this.orderId, priceType: 1 })

      this.$store.commit('set_order_bottom_product', newProduct)
      this.$store.commit('set_order_categoryId', product.categoryId)
      this.$store.commit('set_order_pageType', 'edit')
    },
    formatMoney (number) {
      return toMoney(number)
    },
    formatSchedule (products) {
      if (products.length > 0) {
        const product = products[0]
        return GetCurrentSchedule(product.startDate, product.endDate)
      }
    },
    handleDeleteWarning (product) {
      this.$Modal.confirm({
        title: '操作提示',
        render: h => {
          return h('div', [
            h('span', '您即将删除订单中'),
            h('span', { style: { 'font-weight': 'bold', 'font-size': 'large' } }, product.productName),
            h('span', { style: { 'font-weight': 'bold', 'font-size': 'large', color: '#19be6b' } }, GetCurrentSchedule(product.startDate, product.endDate)),
            h('span', {}, '资源,请谨慎操作')
          ])
        },
        onOk: () => {
          this.handleDeleteProduct(product)
        }
      })
    },
    handleDeleteProduct (product) {
      const data = {
        orderId: this.orderId,
        productIds: JSON.stringify([product.productId]),
        startDate: product.startDate,
        endDate: product.endDate,
        priceType: 1
      }
      deleteProduct(data).then(res => {
        if (res && !res.errcode) {
          this.$Notice.success({ desc: '删除成功' })
          if (product === this.chooseProduct) {
            this.$store.commit('set_order_defaultProduct', undefined)
          }

          if (this.categoryId === product.categoryId) {
            this.$store.commit('set_order_update_left', new Date())
          }

          this.initPageData()
        }
      })
    },
    getMoney (rules) {
      let total = 0
      if (rules) {
        rules.forEach(rule => {
          total += rule.usePrice
        })
      }
      return toMoney(total)
    }
  },
  computed: {
    defaultProduct () {
      return this.$store.state.order.defaultProduct
    },
    orderId () {
      return this.$store.state.order.orderId
    },
    mapType () {
      return this.$store.state.order.mapType
    },
    beginUpdatePurchase () {
      return this.$store.state.order.beginUpdatePurchase
    },
    orderBean () {
      return this.$store.state.order.orderBean
    },
    categoryId () {
      return this.$store.state.order.categoryId
    }
  },
  watch: {
    beginUpdatePurchase (val) {
      this.initPageData()
    },
    orderId () {
      this.initPageData()
    },
    defaultProduct (val) {
      if (!val) { // 清空对应的值
        this.initPageData()
      }
    }
  }
}
</script>
